import React from 'react';
import { Link } from "gatsby"
import Layout from '../components/layout'
import SEO from "../components/seo"
import { Row, Col } from 'reactstrap'



const Thanks = () => {

  return (
    <Layout location="/contact">
    <SEO
      title='404 - Page Not Found'
    />
      <Row className="bg-dark my-3">
      <Col md={{ size: 12 }} className="p-3">

      <h2>404 </h2>
      <h3 className="text-white">We couldn't find that page</h3>
                    <Link className="btn btn-primary mt-5 mb-5" to="/">Return Home</Link>
      </Col></Row>
      
    </Layout>
  )
}

export default Thanks





